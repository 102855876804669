<template>
  <div class="flex-grow pb-10 text-green flex flex-col justify-center items-center">
    <div v-if="!ready" class="pb-6">
      <p v-if="web3.user.connection == WEB3_STATE.CONNECTED" class="font-semibold">LOADING SWAPS...</p>
      <p v-if="web3.user.connection == WEB3_STATE.INSTALL" class="font-semibold">INSTALL WALLET</p>

      <button class="btn btn-pink w-56 h-16" @click="web3.connectWallet" v-if="web3.user.connection == WEB3_STATE.CONNECT">connect wallet</button>
      <button class="btn btn-pink w-56 h-16" @click="web3.correctNetwork(null)" v-if="web3.user.connection == WEB3_STATE.WRONG_NETWORK">switch network</button>
    </div>

    <div v-if="ready && swaps.current.state == null && swaps.bids.current == null && !swaps.bids.show" class="w-[512px] pb-6">
      <div
        v-if="swaps.privateSwaps.length == 0 && swaps.openSwaps.length == 0"
        class="flex-grow flex flex-col justify-center items-center">
        <p class="font-semibold">NO SWAPS YET</p>
      </div>

      <div v-if="swaps.privateSwaps.length > 0" class="w-full mb-16">
        <p class="mb-4 font-semibold text-center">private swaps</p>
        <Swaps type="buy" :swapArray="swaps.privateSwaps" />
      </div>

      <div v-if="swaps.openSwaps.length > 0" class="flex flex-col items-stretch relative">
        <div class="flex justify-between">
          <button
            class="btn btn-green mb-4 px-6 z-10"
            :class="{ 'invisible': swaps.bids.buyerBids == null || swaps.bids.buyerBids.length == 0 }"
            @click="swaps.showBids">your bids</button>
          <Select
            class="mb-4 pr-12 pl-4"
            v-model="swaps.sorting.buy"
            :options="Object.keys(SWAP_SORTING).map(key => SWAP_SORTING[key])"
            @update="(order) => swaps.setSortingBuy(order)" />
        </div>
        <p class="top-6 left-0 right-0 text-center font-semibold absolute">all swaps</p>
        <Swaps type="buy" :swapArray="swaps.openSwaps" />
      </div>
    </div>

    <div v-if="ready && swaps.current.state != null && swaps.bids.current == null && !swaps.bids.show" class="w-[360px] pt-6">
      <SwapBuy v-if="swaps.current.state == SWAP_STATE.FUNDED" />
      <SwapBought v-if="swaps.current.state == SWAP_STATE.SOLD" />
    </div>

    <div v-if="ready && swaps.bids.current == null && swaps.current.state == null && swaps.bids.show" class="w-[512px] pt-6 text-purple relative">
      <div class="flex justify-end" :class="{ 'justify-center': swaps.tx.state != TX_STATE.NONE }">
        <button
          v-if="swaps.tx.state == TX_STATE.NONE"
          class="btn btn-purple h-[52px] mb-4 px-6 z-10"
          @click="swaps.cancelAllBids">cancel all</button>
        <div
          v-if="swaps.tx.state != TX_STATE.NONE"
          class="h-16 px-8 border-2 text-purple flex font-semibold justify-center items-center">
          <div class="spinner mr-5"></div>
          <p v-if="swaps.tx.state == TX_STATE.WAITING">waiting for confirmation...</p>
          <p v-if="swaps.tx.state == TX_STATE.PENDING">tx pending...</p>
        </div>
      </div>
      <p v-if="swaps.tx.state == TX_STATE.NONE" class="top-12 left-0 right-0 text-center font-semibold absolute">your bids</p>
      <Bids v-if="swaps.tx.state == TX_STATE.NONE" :bids="swaps.bids.buyerBids" type="all" />

      <div v-if="swaps.tx.state == TX_STATE.NONE" class="mt-6 flex justify-center">
        <p class="cursor-pointer select-none border-b border-transparent hover:border-current" @click="swaps.hideBids">back</p>
      </div>
    </div>

    <div v-if="ready && swaps.bids.current == null && swaps.current.state != null && swaps.bids.show" class="w-[360px] pt-6 text-purple">
      <p class="mb-4 font-semibold text-center">bids</p>
      <Bids class="mb-6" :bids="swaps.current.bids" type="buy" />

      <Row type='info' :data="{ text: 'current ask', value: getPricePerJewel(swaps.current.ask.amount, swaps.current.lockedJewelBalance) }" :top="true" />
      <Row type='info' :data="{ text: 'locked jewel amount', value: formatNumber(swaps.current.lockedJewelBalance) }" :bottom="true" />

      <button class="btn btn-purple w-full h-16 mt-6" @click="swaps.initBid">place bid</button>

      <div class="mt-6 flex justify-center">
        <p class="cursor-pointer select-none border-b border-transparent hover:border-current" @click="swaps.hideBids">back</p>
      </div>
    </div>

    <div v-if="ready && swaps.bids.current != null" class="w-[360px] pt-6 text-purple">
      <SwapBidPlace v-if="swaps.bids.current.amount == null" />
      <SwapBidPlaced v-if="swaps.bids.current.amount != null" />
    </div>
  </div>
</template>

<script>
import web3 from '@/state/web3'
import swaps from '@/state/swaps'

import Row from '@/components/Row.vue';
import Select from '@/components/Select.vue';

import { Swaps, SwapBuy, SwapBought, Bids, SwapBidPlace, SwapBidPlaced } from '@/components'
import { SWAP_STATE, SWAP_SORTING, WEB3_STATE, TX_STATE } from '@/constants'
import { getPricePerJewel, formatNumber, formatDollars } from '@/utils';

export default {
  name: 'Buy',
  components: {
    Row,
    Select,

    Swaps,
    SwapBuy,
    SwapBought,

    Bids,
    SwapBidPlace,
    SwapBidPlaced,
  },
  beforeCreate() { swaps.clearCurrentSwap(); },
  data() { return { web3, swaps, getPricePerJewel, formatNumber, formatDollars, SWAP_STATE, SWAP_SORTING, WEB3_STATE, TX_STATE, } },
  computed: {
    ready() {
      return (swaps.openSwaps.value != null && swaps.privateSwaps.value != null) || swaps.current.state != null;
    },
  },
}
</script>