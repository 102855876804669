<template>
  <div class="p-8 mb-4 text-purple font-semibold text-left border-2 whitespace-pre-wrap flex flex-col">
    <div v-for="(section, i) in INFO" :key="section.title">
      <p class="mb-1 border-b inline-block leading-5">{{ section.title }}</p>
      <p>{{ section.text }}</p>
      <br v-if="i != (INFO.length - 1)">
    </div>
    
    <div class="flex mb-1">
      <p>factory:</p>
      <p
        class="my-1 ml-2 leading-4 font-semibold cursor-pointer select-none border-b border-transparent hover:border-current"
        @click="openAddress(JEWELSWAP_ADDRESS)">{{ formatAddress(JEWELSWAP_ADDRESS) }}</p>
    </div>
    <div class="flex">
      <p>swap:</p>
      <p
        class="my-1 ml-2 leading-4 font-semibold cursor-pointer select-none border-b border-transparent hover:border-current"
        @click="openAddress(JEWELSWAP_WALLET_ADDRESS)">{{ formatAddress(JEWELSWAP_WALLET_ADDRESS) }}</p>
    </div>
  </div>
</template>

<script>
import swaps from '@/state/swaps'

import { INFO, GITHUB_URL } from '@/constants'
import { formatAddress, openAddress } from '@/utils'
import { JEWELSWAP_ADDRESS, JEWELSWAP_WALLET_ADDRESS } from '@/addresses';

export default {
  name: 'Info',
  beforeCreate() { swaps.clearCurrentSwap(); },
  data() { return { INFO, formatAddress, openAddress, JEWELSWAP_ADDRESS, JEWELSWAP_WALLET_ADDRESS } },
  methods: {
    openGithub() {
      window.open(GITHUB_URL, '_blank').focus();
    },
  }
}
</script>