<template>
  <div class="flex flex-col">
    <p class="mb-4 font-semibold text-center">step 1 of 2</p>

    <div class="px-6 py-4 border-2 border-b-0 flex flex-col">
      <p class="mb-2 font-semibold">price per jewel</p>
      <Input class="mb-4" v-model="pricePerJewel" type="number" placeholder="$0" />

      <p class="mb-2 font-semibold">currency</p>
      <Select class="mb-4" v-model="currency" :options="Object.keys(CURRENCIES)" @update="(selectedCurrency) => currency = selectedCurrency" />

      <div class="mb-2 flex items-center">
        <p class="font-semibold">buyer</p>
        <p class="text-xs whitespace-pre"> (leave empty to make public)</p>
      </div>
      <Input class="mb-4" v-model="buyer" placeholder="0x..." />
    </div>
    <Row type='info' :data="{ text: 'locked jewel amount', value: formatNumber(contracts.data.lockedJewelBalance) }" />
    <Row type='info' :data="{ text: 'you receive', value: getTotalReceivedPPJ(pricePerJewel, contracts.data.lockedJewelBalance) }" :bottom="true" />

    <button v-if="swaps.tx.state == TX_STATE.NONE" class="btn btn-pink h-16 mt-6" @click="createSwap">create swap</button>
    <div v-if="swaps.tx.state != TX_STATE.NONE" class="h-16 mt-6 border-2 flex font-semibold justify-center items-center">
      <div class="spinner mr-5"></div>
      <p v-if="swaps.tx.state == TX_STATE.WAITING">waiting for confirmation...</p>
      <p v-if="swaps.tx.state == TX_STATE.PENDING">tx pending...</p>
    </div>

    <div v-if="errorMsg != null" class="mt-4 -mb-2 flex justify-center">
      <p class="text-red text-center font-semibold">{{ errorMsg }}</p>
    </div>

    <div class="mt-6 flex justify-center" :class="{ 'invisible': swaps.tx.state != TX_STATE.NONE }">
      <p class="cursor-pointer select-none border-b border-transparent hover:border-current" @click="swaps.clearCurrentSwap">back</p>
    </div>
  </div>
</template>

<script>
import swaps from '@/state/swaps'
import contracts from '@/state/contracts';

import Row from '@/components/Row.vue';
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';

import { CURRENCIES, TX_STATE } from '@/constants';
import { formatNumber, getTotalReceivedPPJ, parseError } from '@/utils';

export default {
  name: 'SwapCreate',
  components: {
    Row,
    Input,
    Select
  },
  data() {
    return {
      // Imports
      swaps,
      contracts,
      CURRENCIES,
      TX_STATE,
      formatNumber,
      getTotalReceivedPPJ,

      // Input
      pricePerJewel: null,
      currency: Object.keys(CURRENCIES)[0],
      buyer: null,

      // Error handling
      parseError,
      errorMsg: null,
    }
  },
  methods: {
    async createSwap() {
      try {
        this.errorMsg = null;
        await swaps.createSwap(this.pricePerJewel, this.currency, this.buyer);
      } catch (err) {
        this.errorMsg = parseError(err) || `failed to create swap`;
      }
    }
  },
  watch: {
    // Sanitization
    pricePerJewel: {
      handler() {
        this.$nextTick(() => {
          // Ensure no negative values
          if (this.pricePerJewel < 0) {
            this.pricePerJewel = '0';
          }

          // Limit to two digits after decimal
          if (this.pricePerJewel != null) {
            const price = this.pricePerJewel;
            this.pricePerJewel = (price.indexOf(".") >= 0) ? (price.substr(0, price.indexOf(".")) + price.substr(price.indexOf("."), 3)) : price;
          }
        });
      },
      immediate: true
    },
  },
}
</script>