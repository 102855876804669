<template>
  <div class="flex flex-col flex-grow">
    <p class="mb-4 font-semibold text-center">swap</p>

    <Row type='info' :data="{ text: 'seller', value: swaps.current.seller, type: 'address' }" :top="true" />
    <Row type='info' :data="{ text: 'swap contract', value: swaps.current.walletAddress, type: 'address' }" />
    <Row type='info' :data="{ text: 'price per jewel', value: getPricePerJewel(swaps.current.ask.amount, swaps.current.lockedJewelBalance) }" />
    <Row type='info' :data="{ text: 'locked jewel amount', value: formatNumber(swaps.current.lockedJewelBalance) }" />
    <Row type='info' :data="{ text: 'total', value: `${formatNumber(swaps.current.ask.amount)} ${swaps.current.ask.currency.name}` }" :bottom="true" />

    <div v-if="swaps.current.bids != null && swaps.tx.state == TX_STATE.NONE && !approved" class="mt-6 flex flex-col">
      <button
        v-if="swaps.current.bids.length == 0"
        class="btn btn-green h-16 px-6 flex-grow flex group"
        @click="swaps.initBid">
        <p class="font-normal group-hover:hidden">no bids yet</p>
        <p class="flex-grow text-center text-white hidden group-hover:block">place bid</p>
      </button>
      <button
        v-if="swaps.current.bids.length > 0"
        class="btn btn-purple h-16 px-6 flex-grow flex justify-between group"
        @click="swaps.showBids">
        <p class="group-hover:hidden">highest bid</p>
        <p class="ml-2 font-semibold group-hover:hidden">{{ getPricePerJewel(swaps.current.bids[0].amount, swaps.current.lockedJewelBalance) }}</p>
        <p class="flex-grow text-white hidden group-hover:block">show all bids</p>
      </button>
    </div>

    <button
      v-if="swaps.tx.state == TX_STATE.NONE && contracts.allowances[swaps.current.ask.currency.name].gte(swaps.current.ask.amount)"
      class="btn btn-green h-16 mt-6"
      @click="acceptSwap">buy now</button>
    <button
      v-if="swaps.tx.state == TX_STATE.NONE && contracts.allowances[swaps.current.ask.currency.name].lt(swaps.current.ask.amount)"
      class="btn btn-green h-16 mt-6 group"
      @click="approveCurrency">
      <p class="group-hover:hidden">buy now</p>
      <p class="flex-grow text-white text-center hidden group-hover:block">approve {{ swaps.current.ask.currency.name }}</p>
    </button>

    <div v-if="swaps.tx.state != TX_STATE.NONE" class="h-16 mt-6 flex-grow border-2 flex font-semibold justify-center items-center">
      <div class="spinner mr-5"></div>
      <p v-if="swaps.tx.state == TX_STATE.WAITING">waiting for confirmation...</p>
      <p v-if="swaps.tx.state == TX_STATE.PENDING">tx pending...</p>
    </div>

    <div v-if="errorMsg != null" class="mt-4 -mb-2 flex justify-center">
      <p class="text-red text-center font-semibold">{{ errorMsg }}</p>
    </div>
    
    <div class="mt-6 flex justify-center" :class="{ 'invisible': swaps.tx.state != TX_STATE.NONE }">
      <p class="cursor-pointer select-none border-b border-transparent hover:border-current" @click="swaps.clearCurrentSwap">back</p>
    </div>
  </div>
</template>

<script>
import swaps from '@/state/swaps';
import contracts from '@/state/contracts';

import Row from '@/components/Row.vue';

import { TX_STATE } from '../constants';
import { formatNumber, getPricePerJewel, parseError } from '@/utils';

export default {
  name: 'SwapBuy',
  components: {
    Row,
  },
  data() { return { swaps, contracts, TX_STATE, formatNumber, getPricePerJewel, parseError, errorMsg: null, approved: false, } },
  methods: {
    async approveCurrency() {
      try {
        this.errorMsg = null;
        await swaps.approveCurrency(swaps.current.ask.currency.name);
        this.approved = true;
      } catch (err) {
        this.errorMsg = parseError(err) || `failed to approve ${swaps.current.ask.currency.name}`;
      }
    },
    async acceptSwap() {
      try {
        this.errorMsg = null;
        await swaps.acceptSwap();
      } catch (err) {
        this.errorMsg = parseError(err) || `failed to buy locked jewel`;
      }
    },
  }
}
</script>