<template>
  <div class="flex">
    
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {
    
  },
}
</script>