import { ref, reactive, readonly, watch, watchEffect } from 'vue'

import web3 from '@/state/web3';
import contracts from '@/state/contracts';
import { ethers, BigNumber } from 'ethers';
import { getCurrencyName, getPricePerJewel, getTotalPPJ, amountMultiplier } from '@/utils'
import { CURRENCIES, ZERO_ADDRESS, SWAP_STATE, SWAP_SORTING, PAGE_SIZE, TX_STATE, GAS_PRICE } from '@/constants';
import { JEWELSWAP_ADDRESS } from '@/addresses';
import { EXPIRATION_INTERVALS } from '../constants';

/*
///////////////////////////////////////////////////////////////
												STATE
//////////////////////////////////////////////////////////////
*/

const current = reactive({
	id: null,
	walletAddress: null,
	seller: null,
	buyer: null,
	ask: null,
	bids: null,
	sale: null,
	state: null,
	lockedJewelBalance: null,
	createdAt: null,
	updatedAt: null,
});

const bids = reactive({
	show: false,
	current: null,
	buyerBids: null,
});

const sellerSwaps = ref();
const privateSwaps = ref();
const openSwaps = ref();
const completedSwaps = ref();

const sorting = reactive({
	buy: SWAP_SORTING.LOWEST_PRICE,
	activity: SWAP_SORTING.MOST_RECENT,
});

const tx = reactive({
	state: TX_STATE.NONE
});

/*
///////////////////////////////////////////////////////////////
												SELLING
//////////////////////////////////////////////////////////////
*/

const initSwap = () => {
	current.state = SWAP_STATE.EMPTY;
}

const setCurrentSwap = (swap) => {
	clearCurrentSwap();
	Object.keys(swap).map(key => {
		current[key] = swap[key];
	});
}

const clearCurrentSwap = () => {
	tx.state = TX_STATE.NONE;
	Object.keys(current).map(key => {
		current[key] = null;
	});
	hideBids();
}

const startSwapEdit = () => {
	current.state = SWAP_STATE.EDIT;
}

const cancelSwapEdit = () => {
	current.state = SWAP_STATE.FUNDED;
}

const createSwap = async (pricePerJewel, currency, buyer) => {
	if (contracts.data.lockedJewelBalance == 0) { throw { msg: 'you have no locked jewel', custom: true }; }
	if (pricePerJewel == null || pricePerJewel == '') { throw { msg: 'price per jewel not set', custom: true }; }
	if (buyer != null && buyer != '' && !ethers.utils.isAddress(buyer)) { throw { msg: 'invalid address', custom: true }; }
	if (!contracts.ready.value) { throw { msg: 'try again in a moment', custom: true }; }
	if (contracts.data.unlockedJewelBalance > 0) {
		throw { msg: 'transfer your unlocked $JEWEL out of your wallet before creating a swap', custom: true };
	}

	try {
		// Keep track of current swap id
		const currentId = current.id;

		// Update tx state
		tx.state = TX_STATE.WAITING;

		// Sanitize address
		buyer = buyer || ZERO_ADDRESS;

		// Get total payment amount
		const amount = getTotalPPJ(pricePerJewel, contracts.data.lockedJewelBalance, true).div(amountMultiplier(currency)).add(1);

		// Create currency object
		currency = {
			name: currency,
			address: CURRENCIES[currency]
		};

		// Estimate gas
		let gasLimit = await contracts.JewelSwap.value.connect(web3.user.signer).estimateGas.createSwap(amount, currency.address, buyer);
		gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// Create swap
		const response = await contracts.JewelSwap.value.connect(web3.user.signer).createSwap(amount, currency.address, buyer, { gasLimit, gasPrice: GAS_PRICE });
		
		// Await tx confirmation
		tx.state = TX_STATE.PENDING;
		const receipt = await response.wait(1);

		// Update current swap
		if (currentId == current.id) {
			// Get swap address
			const id = parseEvents(receipt.events, 'id');
			const swap = await contracts.JewelSwap.value.getWallet(id);
			const walletAddress = swap.walletAddress;

			// Update data
			current.id = id;
			current.walletAddress = walletAddress;
			current.seller = web3.user.address;
			current.buyer = buyer;
			current.ask = {
				amount: amount.mul(amountMultiplier(currency.name)),
				currency: currency,
			};
			current.lockedJewelBalance = contracts.data.lockedJewelBalance;
			current.state = SWAP_STATE.CREATED;
		}

		// Update tx state
		tx.state = TX_STATE.NONE;
	} catch (err) {
		tx.state = TX_STATE.NONE;
		throw { msg: err };
	}
}

const fundSwap = async () => {
	if (current.walletAddress == null || current.walletAddress == ZERO_ADDRESS) { throw { msg: 'invalid swap address', custom: true }; }
	if (!contracts.ready.value) { throw { msg: 'try again in a moment', custom: true }; }
	if (contracts.data.unlockedJewelBalance > 0) {
		throw { msg: 'transfer your unlocked $JEWEL out of your wallet before funding a swap', custom: true };
	}

	try {
		// Keep track of current swap id
		const currentId = current.id;

		// Update tx state
		tx.state = TX_STATE.WAITING;

		// Estimate gas
		let gasLimit = await contracts.JewelToken.value.connect(web3.user.signer).estimateGas.transferAll(current.walletAddress);
		gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// Fund swap
		const response = await contracts.JewelToken.value.connect(web3.user.signer).transferAll(current.walletAddress, { gasLimit, gasPrice: GAS_PRICE });

		// Await tx confirmation
		tx.state = TX_STATE.PENDING;
		await response.wait(1);

		// Reload contract data
		contracts.getData();

		// Reload seller and open swaps
		getSellerSwaps();
		getOpenSwaps();

		// Update swap
		if (currentId == current.id) {
			current.state = SWAP_STATE.FUNDED;
		}

		// Update tx state
		tx.state = TX_STATE.NONE;
	} catch (err) {
		tx.state = TX_STATE.NONE;
		throw { msg: err };
	}
}

const updateSwap = async (pricePerJewel, currency) => {
	if (pricePerJewel == null || pricePerJewel == '') { throw { msg: 'price per jewel not set', custom: true }; }
	if (!contracts.ready.value) { throw { msg: 'try again in a moment', custom: true }; }

	try {
		// Keep track of current swap id
		const currentId = current.id;

		// Update tx state
		tx.state = TX_STATE.WAITING;

		// Get total payment amount
		const amount = getTotalPPJ(pricePerJewel, current.lockedJewelBalance, true).div(amountMultiplier(currency)).add(1);

		// Create currency object
		currency = {
			name: currency,
			address: CURRENCIES[currency]
		};

		// Estimate gas
		let gasLimit = await contracts.JewelSwap.value.connect(web3.user.signer).estimateGas.updateSwap(current.id, amount, currency.address);
		gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// Create swap
		const response = await contracts.JewelSwap.value.connect(web3.user.signer).updateSwap(current.id, amount, currency.address, { gasLimit, gasPrice: GAS_PRICE });
		
		// Await tx confirmation
		tx.state = TX_STATE.PENDING;
		await response.wait(1);

		// Reload seller and open swaps
		getSellerSwaps();
		getOpenSwaps();

		// Update current swap
		if (currentId == current.id) {
			current.ask = {
				amount: amount.mul(amountMultiplier(currency.name)),
				currency: currency,
			};
			current.state = SWAP_STATE.FUNDED;
		}

		// Update tx state
		tx.state = TX_STATE.NONE;
	} catch (err) {
		tx.state = TX_STATE.NONE;
		throw { msg: err };
	}
}

const cancelSwap = async () => {
	if (!contracts.ready.value) { throw { msg: 'try again in a moment', custom: true }; }

	try {
		// Keep track of current swap id
		const currentId = current.id;

		// Update tx state
		tx.state = TX_STATE.WAITING;

		// Estimate gas
		let gasLimit = await contracts.JewelSwap.value.connect(web3.user.signer).estimateGas.cancelSwap(current.id);
		gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// Cancel swap
		const response = await contracts.JewelSwap.value.connect(web3.user.signer).cancelSwap(current.id, { gasLimit, gasPrice: GAS_PRICE });

		// Await tx confirmation
		tx.state = TX_STATE.PENDING;
		await response.wait(1);

		// Reload contract data
		contracts.getData();

		// Reload seller swaps
		getSellerSwaps();

		// Clear swap
		if (currentId == current.id) {
			clearCurrentSwap();
		}

		// Update tx state
		tx.state = TX_STATE.NONE;
	} catch (err) {
		tx.state = TX_STATE.NONE;
		throw { msg: err };
	}
}

const getSellerSwaps = async () => {
	sellerSwaps.value = null;
	const ids = await contracts.JewelSwap.value.getSellerWalletIds(web3.user.address);
	sellerSwaps.value = (await getSwaps(ids)).filter(swap => swap.state == SWAP_STATE.FUNDED);
}

/*
///////////////////////////////////////////////////////////////
												BUYING
//////////////////////////////////////////////////////////////
*/

const approveCurrency = async (currency) => {
	if (!contracts.ready.value) { throw { msg: 'try again in a moment', custom: true }; }

	try {
		// Update tx state
		tx.state = TX_STATE.WAITING;

		// Estimate gas
		let gasLimit = await contracts.Currency[currency].connect(web3.user.signer).estimateGas.approve(JEWELSWAP_ADDRESS, ethers.constants.MaxUint256);
		gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// Approve currency
		const response = await contracts.Currency[currency].connect(web3.user.signer).approve(JEWELSWAP_ADDRESS, ethers.constants.MaxUint256, { gasLimit, gasPrice: GAS_PRICE });

		// Await tx confirmation
		tx.state = TX_STATE.PENDING;
		await response.wait(1);

		// Reload allowances
		await contracts.getAllowances();

		// Update tx state
		tx.state = TX_STATE.NONE;
	} catch (err) {
		tx.state = TX_STATE.NONE;
		throw { msg: err };
	}
}

const acceptSwap = async () => {
	if (!contracts.ready.value) { throw { msg: 'try again in a moment', custom: true }; }

	try {
		// Keep track of current swap id
		const currentId = current.id;

		// Update tx state
		tx.state = TX_STATE.WAITING;

		// Estimate gas
		let gasLimit = await contracts.JewelSwap.value.connect(web3.user.signer).estimateGas.acceptSwap(current.id);
		gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// Fund swap
		const response = await contracts.JewelSwap.value.connect(web3.user.signer).acceptSwap(current.id, { gasLimit, gasPrice: GAS_PRICE });

		// Await tx confirmation
		tx.state = TX_STATE.PENDING;
		await response.wait(1);
		
		// Reload contract data
		contracts.getData();

		// Reload open, private, and completed swaps
		getOpenSwaps();
		getPrivateSwaps();
		getCompletedSwaps();

		// Update swap
		if (currentId == current.id) {
			current.state = SWAP_STATE.SOLD;
		}

		// Update tx state
		tx.state = TX_STATE.NONE;
	} catch (err) {
		tx.state = TX_STATE.NONE;
		if (err.data.message == 'execution reverted: ERC20: transfer amount exceeds balance' && current.ask.currency != null) {
			throw { msg: `not enough ${current.ask.currency.name}`, custom: true };
		} else {
			throw { msg: err };
		}
	}
}

const setSortingBuy = (order) => {
	sorting.buy = order;
}

const setSortingActivity = (order) => {
	sorting.activity = order;
}

const getPrivateSwaps = async () => {
	privateSwaps.value = null;
	const ids = await contracts.JewelSwap.value.getBuyerWalletIds(web3.user.address);
	privateSwaps.value = (await getSwaps(ids)).filter(swap => swap.state == SWAP_STATE.FUNDED);
}

const getOpenSwaps = async () => {
	openSwaps.value = null;
	const ids = await contracts.JewelSwap.value.getOpenSwaps();
	const swaps = (await getSwaps(ids)).filter(swap => {
		return (swap.state == SWAP_STATE.FUNDED && swap.buyer == ZERO_ADDRESS);
	});
	openSwaps.value = sortSwaps(swaps, sorting.buy);
}

/*
///////////////////////////////////////////////////////////////
												BIDS
//////////////////////////////////////////////////////////////
*/

const initBid = () => {
	bids.show = true;
	bids.current = {
		amount: null,
		current: null,
		buyer: null,
		expiryTime: null,
		swap: null,
	}
}

const showBids = () => {
	bids.show = true;
}

const hideBids = () => {
	bids.show = false;
	clearCurrentBid();
}

const setCurrentBid = (bid) => {
	bids.current = bid;
}

const clearCurrentBid = () => {
	bids.current = null;
}

const placeBid = async (pricePerJewel, currency, expiresIn) => {
	if (pricePerJewel == null || pricePerJewel == '') { throw { msg: 'price per jewel not set', custom: true }; }
	if (!contracts.ready.value) { throw { msg: 'try again in a moment', custom: true }; }

	try {
		// Keep track of current swap id
		const currentId = current.id;

		// Update tx state
		tx.state = TX_STATE.WAITING;

		// Get total payment amount
		const amount = getTotalPPJ(pricePerJewel, current.lockedJewelBalance, true).div(amountMultiplier(currency)).add(1);

		// Create currency object
		currency = {
			name: currency,
			address: CURRENCIES[currency]
		};

		// Calculate expiryTime
		const currentTimestamp = BigNumber.from(Math.floor(Date.now() / 1000));
		const expiryTime = EXPIRATION_INTERVALS[expiresIn].add(currentTimestamp);

		// Estimate gas
		let gasLimit = await contracts.JewelSwap.value.connect(web3.user.signer).estimateGas.placeBid(current.id, amount, currency.address, expiryTime);
		gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// Accept bid
		const response = await contracts.JewelSwap.value.connect(web3.user.signer).placeBid(current.id, amount, currency.address, expiryTime, { gasLimit, gasPrice: GAS_PRICE });

		// Await tx confirmation
		tx.state = TX_STATE.PENDING;
		const receipt = await response.wait(1);

		// TODO - think about currentId for bids

		// Check if swap was completed
		const didCompleteSwap = parseEvents(receipt.events, 'didCompleteSwap');
		if (didCompleteSwap) {
			// Reload contract data
			contracts.getData();

			// Reload open, private, and completed swaps
			getOpenSwaps();
			getPrivateSwaps();
			getCompletedSwaps();
	
			// Update swap
			if (currentId == current.id) {
				current.state = SWAP_STATE.SOLD;

				// Hide bids
				hideBids();
			}
		} else {
			// Reload buyer bids, open swaps, and private swaps
			getBuyerBids();
			getOpenSwaps();
			getPrivateSwaps();

			// Update current swap and bid
			if (currentId == current.id) {
				bids.current.amount = amount.mul(amountMultiplier(currency.name));
				bids.current.currency = currency;
				bids.current.buyer = web3.user.address;
				bids.current.expiryTime = expiryTime;
				bids.current.swap = current;

				// Update bids from the chain
				const updatedSwap = (await getSwaps([current.id]))[0];
				current.bids = updatedSwap.bids;
			}
		}

		// Update tx state
		tx.state = TX_STATE.NONE;
	} catch (err) {
		tx.state = TX_STATE.NONE;
		if (err.data.message == 'execution reverted: you do not have enough tokens') {
			throw { msg: `not enough ${currency.name}`, custom: true };
		} else {
			throw { msg: err };
		}
	}
}

const acceptBid = async () => {
	if (!contracts.ready.value) { throw { msg: 'try again in a moment', custom: true }; }

	try {
		// Keep track of current swap id
		const currentId = current.id;

		// Update tx state
		tx.state = TX_STATE.WAITING;

		// Estimate gas
		let gasLimit = await contracts.JewelSwap.value.connect(web3.user.signer).estimateGas.acceptBid(current.id, bids.current.buyer);
		gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// Accept bid
		const response = await contracts.JewelSwap.value.connect(web3.user.signer).acceptBid(current.id, bids.current.buyer, { gasLimit, gasPrice: GAS_PRICE });

		// Await tx confirmation
		tx.state = TX_STATE.PENDING;
		await response.wait(1);
		
		// Reload contract data
		contracts.getData();

		// Reload seller swaps, completed swaps, and buyer bids
		getSellerSwaps();
		getCompletedSwaps();
		getBuyerBids();

		// Update swap
		if (currentId == current.id) {
			current.state = SWAP_STATE.SOLD;
		}

		// Update tx state
		tx.state = TX_STATE.NONE;
	} catch (err) {
		tx.state = TX_STATE.NONE;
		throw { msg: err };
	}
}

const cancelBid = async (id) => {
	if (!contracts.ready.value) { throw { msg: 'try again in a moment', custom: true }; }

	try {
		// Keep track of current swap id
		const currentId = id;

		// Update tx state
		tx.state = TX_STATE.WAITING;

		// Estimate gas
		let gasLimit = await contracts.JewelSwap.value.connect(web3.user.signer).estimateGas.cancelBid(id);
		gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// Cancel swap
		const response = await contracts.JewelSwap.value.connect(web3.user.signer).cancelBid(id, { gasLimit, gasPrice: GAS_PRICE });

		// Await tx confirmation
		tx.state = TX_STATE.PENDING;
		await response.wait(1);

		// Reload buyer, open, and private swaps
		getBuyerBids();
		getOpenSwaps();
		getPrivateSwaps();

		// Clear current bid and update swap
		if (currentId == id) {
			// Update bids from the chain
			const updatedSwap = (await getSwaps([id]))[0];
			current.bids = updatedSwap.bids;
			
			// Go back to swap page if no more active bids
			if (current.bids.length == 0) {
				hideBids();
			} else {
				clearCurrentBid();
			}
		}

		// Update tx state
		tx.state = TX_STATE.NONE;
	} catch (err) {
		tx.state = TX_STATE.NONE;
		throw { msg: err };
	}
}

const cancelAllBids = async () => {
	if (!contracts.ready.value) { throw { msg: 'try again in a moment', custom: true }; }

	try {
		// Update tx state
		tx.state = TX_STATE.WAITING;

		// Estimate gas
		let gasLimit = await contracts.JewelSwap.value.connect(web3.user.signer).estimateGas.cancelAllBids();
		gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// Cancel swap
		const response = await contracts.JewelSwap.value.connect(web3.user.signer).cancelAllBids({ gasLimit, gasPrice: GAS_PRICE });

		// Await tx confirmation
		tx.state = TX_STATE.PENDING;
		await response.wait(1);

		// Reload buyer, open, and private swaps
		getBuyerBids();
		getOpenSwaps();
		getPrivateSwaps();

		// Hide bids
		hideBids();

		// Update tx state
		tx.state = TX_STATE.NONE;
	} catch (err) {
		tx.state = TX_STATE.NONE;
		throw { msg: err };
	}
}

const getBuyerBids = async () => {
	bids.buyerBids = null;
	const ids = await contracts.JewelSwap.value.getBuyerBidIds(web3.user.address);
	bids.buyerBids = await getBids(ids);
}

/*
///////////////////////////////////////////////////////////////
												HISTORY
//////////////////////////////////////////////////////////////
*/

const getCompletedSwaps = async () => {
	completedSwaps.value = null;
	const ids = await contracts.JewelSwap.value.getCompletedSwaps();
	const swaps = await getSwaps(ids);
	completedSwaps.value = sortSwaps(swaps, sorting.activity);
}

/*
///////////////////////////////////////////////////////////////
												INTERNAL
//////////////////////////////////////////////////////////////
*/

const getSwaps = async (ids) => {
	// Make a copy of id array
	ids = [...ids];

	// Page swap ids
	let pages = [];
	while (ids.length > 0) {
		pages.push(ids.splice(0, PAGE_SIZE));
	}

	// Get swaps in parallel
	let swaps = [];
	await Promise.all(pages.map(async (page) => {
		// Get properties
		const { _walletAddresses, _sellers, _buyers, _asks, _bids, _sales, _states, _lockedJewelAmounts, _createdAt, _updatedAt } = await contracts.JewelSwap.value.getWallets(page);
		
		// Parse properties
		for (let i = 0; i < _walletAddresses.length; i++) {
			// Parse state
			let state = _states[i];
			if (state == 0) {
				if (_lockedJewelAmounts[i] > 0) {
					state = SWAP_STATE.FUNDED;
				} else {
					state = SWAP_STATE.CREATED;
				}
			} else if (state == 1) {
				state = SWAP_STATE.SOLD;
			} else if (state == 2) {
				state = SWAP_STATE.CANCELED;
			}

			// Parse ask
			let ask = {
				amount: _asks[i].amount.mul(amountMultiplier(getCurrencyName(_asks[i].currency))),
				currency: {
					name: getCurrencyName(_asks[i].currency),
					address: _asks[i].currency
				}
			};

			// Parse bids
			let bids = null;
			if (state == SWAP_STATE.FUNDED && _buyers[i] == ZERO_ADDRESS) {
				bids = [];

				for (const bid of _bids[i]) {
					if (bid.length > 0) {
						// TODO - remove bids whose owners don't have enough currency amount available or approved

						// Push bid
						bids.push({
							amount: bid.amount.mul(amountMultiplier(getCurrencyName(bid.currency))),
							currency: {
								name: getCurrencyName(bid.currency),
								address: bid.currency
							},
							buyer: bid.buyer,
							expiryTime: bid.expiryTime,
							swap: {
								id: page[i],
								walletAddress: _walletAddresses[i],
								seller: _sellers[i],
								lockedJewelBalance: _lockedJewelAmounts[i],
							},
						});
					}
				}

				// Sort bids
				bids = sortBids(bids);
			}

			// Parse sale
			let sale = null;
			if (_sales[i] != null && _sales[i].currency != ZERO_ADDRESS) {
				sale = {
					lockedJewelAmount: _sales[i].lockedJewelAmount,
					amount: _sales[i].amount.mul(amountMultiplier(getCurrencyName(_sales[i].currency))),
					currency: {
						name: getCurrencyName(_sales[i].currency),
						address: _sales[i].currency
					},
					buyer: _sales[i].buyer,
					soldAt: _sales[i].soldAt,
				};
			}

			// Push swap
			swaps.push({
				id: page[i],
				walletAddress: _walletAddresses[i],
				seller: _sellers[i],
				buyer: _buyers[i],
				ask: ask,
				bids: bids,
				sale: sale,
				state: state,
				lockedJewelBalance: _lockedJewelAmounts[i],
				createdAt: _createdAt[i],
				updatedAt: _updatedAt[i],
			});
		}
	}));

	return swaps;
}

const sortSwaps = (swaps, order) => {
	// Make a copy of swaps array
	swaps = [...swaps];

	// Sort swaps
	swaps.sort((a, b) => {
		let ppjA, ppjB, amountA, amountB, timestampA, timestampB;

		if (a.state == SWAP_STATE.FUNDED) {
			ppjA = Number(getPricePerJewel(a.ask.amount, a.lockedJewelBalance).substring(1));
			ppjB = Number(getPricePerJewel(b.ask.amount, b.lockedJewelBalance).substring(1));
			amountA = a.ask.amount;
			amountB = b.ask.amount;
			timestampA = a.updatedAt;
			timestampB = b.updatedAt;
		} else if (a.state == SWAP_STATE.SOLD) {
			ppjA = Number(getPricePerJewel(a.sale.amount, a.sale.lockedJewelAmount).substring(1));
			ppjB = Number(getPricePerJewel(b.sale.amount, b.sale.lockedJewelAmount).substring(1));
			amountA = a.sale.amount;
			amountB = b.sale.amount;
			timestampA = a.sale.soldAt;
			timestampB = b.sale.soldAt;
		}

		if (order == SWAP_SORTING.LOWEST_PRICE) {
			return (ppjA < ppjB) ? -1 : 1;
		} else if (order == SWAP_SORTING.HIGHEST_PRICE) {
			return (ppjA < ppjB) ? 1 : -1;
		} else if (order == SWAP_SORTING.LOWEST_TOTAL) {
			return (amountA.lt(amountB)) ? -1 : 1;
		} else if (order == SWAP_SORTING.HIGHEST_TOTAL) {
			return (amountA.lt(amountB)) ? 1 : -1;
		} else if (order == SWAP_SORTING.MOST_RECENT) {
			return (timestampA.lt(timestampB)) ? 1 : -1;
		}

		return 0;
	});

	return swaps;
}

const getBids = async (ids) => {
	// Make a copy of id array
	ids = [...ids];

	// Page bid ids
	let pages = [];
	while (ids.length > 0) {
		pages.push(ids.splice(0, PAGE_SIZE));
	}

	// Get bids in parallel
	let bids = [];
	await Promise.all(pages.map(async (page) => {
		// Get bids
		const _bids = await contracts.JewelSwap.value.getBuyerBids(page, web3.user.address);

		// Get swaps
		const swaps = await getSwaps(page);
		
		// Parse bids
		for (const [i, bid] of _bids.entries()) {
			// TODO - remove bids whose owners don't have enough currency amount available or approved

			// Push bid
			bids.push({
				amount: bid.amount.mul(amountMultiplier(getCurrencyName(bid.currency))),
				currency: {
					name: getCurrencyName(bid.currency),
					address: bid.currency
				},
        buyer: bid.buyer,
        expiryTime: bid.expiryTime,
				swap: {
					id: swaps[i].id,
					walletAddress: swaps[i].walletAddress,
					seller: swaps[i].seller,
					lockedJewelBalance: swaps[i].lockedJewelBalance,
				},
			});
		}
	}));

	return bids;
}

const sortBids = (bids) => {
	// Make a copy of bids array
	bids = [...bids];

	// Sort bids from highest to lowest
	bids.sort((a, b) => {
		const ppjA = Number(getPricePerJewel(a.amount, a.swap.lockedJewelBalance).substring(1));
		const ppjB = Number(getPricePerJewel(b.amount, b.swap.lockedJewelBalance).substring(1));

		return (ppjA < ppjB) ? 1 : -1;
	});

	return bids;
}

const parseEvents = (events, property) => {
	for (const event of events) {
		if (event.args != null && event.args[property] != null) {
			return event.args[property];
		}
	}

	return null;
}

/*
///////////////////////////////////////////////////////////////
												WATCHERS
//////////////////////////////////////////////////////////////
*/

watchEffect(() => {
	if (web3.user.address != null && contracts.ready.value) {
		getSellerSwaps();
		getPrivateSwaps();
		getOpenSwaps();
		getCompletedSwaps();
		getBuyerBids();
	}
});

watch(() => sorting.buy, () => {
	if (openSwaps.value != null) {
		openSwaps.value = sortSwaps(openSwaps.value, sorting.buy);
	}
});

watch(() => sorting.activity, () => {
	if (completedSwaps.value != null) {
		completedSwaps.value = sortSwaps(completedSwaps.value, sorting.activity);
	}
});

/*
///////////////////////////////////////////////////////////////
												EXPORT
//////////////////////////////////////////////////////////////
*/

export default {
	current: readonly(current),
	bids: readonly(bids),
	sellerSwaps: readonly(sellerSwaps),
	privateSwaps: readonly(privateSwaps),
	openSwaps: readonly(openSwaps),
	completedSwaps: readonly(completedSwaps),
	sorting: readonly(sorting),
	tx: readonly(tx),

	initSwap,
	setCurrentSwap,
	clearCurrentSwap,
	startSwapEdit,
	cancelSwapEdit,

	createSwap,
	fundSwap,
	updateSwap,
	cancelSwap,
	approveCurrency,
	acceptSwap,

	initBid,
	showBids,
	hideBids,
	setCurrentBid,
	clearCurrentBid,

	placeBid,
	acceptBid,
	cancelBid,
	cancelAllBids,
	
	setSortingBuy,
	setSortingActivity,
};