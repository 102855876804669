<template>
  <div class="w-full h-screen px-4 flex flex-col items-center relative overflow-auto">
    <Header />
    <div class="w-full max-w-[800px] h-full flex flex-col">
      <Navigation v-if="$route.name != `home`" />
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
import { Header, Footer, Navigation } from '@/components'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Navigation,
  },
}
</script>