import { ref, reactive, readonly, watchEffect, markRaw } from 'vue'

import web3 from '@/state/web3';
import { BigNumber, ethers } from 'ethers';
import { WEB3_STATE } from '@/constants';
import { JEWELSWAP_ABI, JEWELTOKEN_ABI, ERC20_ABI } from '@/abis/';
import { JEWELSWAP_ADDRESS, JEWELTOKEN_ADDRESS } from '@/addresses';
import { CURRENCIES } from '@/constants';

/*
///////////////////////////////////////////////////////////////
												STATE
//////////////////////////////////////////////////////////////
*/

const JewelSwap = markRaw({});
const JewelToken = markRaw({});
const Currency = markRaw({});

const data = reactive({
	fee: BigNumber.from(10),
	lockedJewelBalance: BigNumber.from(0),
});

const allowances = reactive({});

const currencyDecimals = reactive({});

let ready = ref(false);

/*
///////////////////////////////////////////////////////////////
												PUBLIC
//////////////////////////////////////////////////////////////
*/

const init = async () => {
	try {
		// Only init contracts if you have all abis and addresses
		if (JEWELSWAP_ABI == null || JEWELSWAP_ADDRESS == null || JEWELTOKEN_ABI == null || JEWELTOKEN_ADDRESS == null) {
			return;
		}

		// Init contracts
		JewelSwap.value = new ethers.Contract(JEWELSWAP_ADDRESS, JEWELSWAP_ABI, web3.user.signer);
		JewelToken.value = new ethers.Contract(JEWELTOKEN_ADDRESS, JEWELTOKEN_ABI, web3.user.signer);
		Object.keys(CURRENCIES).map(name => {
			Currency[name] = new ethers.Contract(CURRENCIES[name], ERC20_ABI, web3.user.signer);
		});

		// Get contract data, allowances, and currency decimals
		await Promise.all([getData(), getAllowances(), getCurrencyDecimals()]);

		// TODO remove allownace decrease in init

		// // Estimate gas
		// let gasLimit = await Currency['$BUSD'].connect(web3.user.signer).estimateGas.decreaseAllowance(JEWELSWAP_ADDRESS, allowances['$BUSD']);
		// gasLimit = gasLimit.add(gasLimit.div(5)); // Multiply estimate by 20% to ensure transaction goes through

		// // Approve currency
		// await Currency['$BUSD'].connect(web3.user.signer).decreaseAllowance(JEWELSWAP_ADDRESS, allowances['$BUSD'], { gasLimit, gasPrice: GAS_PRICE });

		// Update state
		ready.value = true;
	} catch (err) {
		ready.value = false;
	}
}

const getData = async () => {
	let contractProperties = [
		JewelSwap.value.fee(),
		JewelSwap.value.getTotalVolumeTraded(),
		JewelSwap.value.feeGracePeriodAmount(),
		JewelToken.value.lockOf(web3.user.address),
		JewelToken.value.balanceOf(web3.user.address),
	];
	let propertyNames = ['fee', 'totalVolumeTraded','feeGracePeriodAmount', 'lockedJewelBalance', 'unlockedJewelBalance'];

	await Promise.all(contractProperties.map(async (promise, i) => {
		data[propertyNames[i]] = await promise;
	}));
}

const getAllowances = async () => {
	await Promise.all(Object.keys(CURRENCIES).map(async (name) => {
		allowances[name] = await Currency[name].allowance(web3.user.address, JEWELSWAP_ADDRESS);
	}));
}

const getCurrencyDecimals = async () => {
	await Promise.all(Object.keys(CURRENCIES).map(async (name) => {
		currencyDecimals[name] = await Currency[name].decimals();
	}));
}

/*
///////////////////////////////////////////////////////////////
												WATCHERS
//////////////////////////////////////////////////////////////
*/

watchEffect(() => {
	if (web3.user.connection == WEB3_STATE.CONNECTED) {
		init();
	}
});

/*
///////////////////////////////////////////////////////////////
												EXPORT
//////////////////////////////////////////////////////////////
*/

export default {
	JewelSwap: readonly(JewelSwap),
	JewelToken: readonly(JewelToken),
	Currency: readonly(Currency),
	data: readonly(data),
	allowances: readonly(allowances),
	currencyDecimals: readonly(currencyDecimals),
	ready: readonly(ready),

	init,
	getData,
	getAllowances,
};