import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router';
import web3 from '@/state/web3'
import '@/index.css'

const app = createApp(App);

web3.init();
app.use(router);

app.mount("#app");