import contracts from '@/state/contracts';
import { BigNumber, ethers } from 'ethers';
import { CURRENCIES, EXPLORER_URL } from '@/constants';

export const formatAddress = (address) => {
  if (address) {
    return `${address.substring(0,6)}...${address.substring(address.length - 4,address.length)}`;
  } else {
    return "";
  }
}

export const formatNumber = (num) => {
  if (BigNumber.isBigNumber(num)) { num = convertBigNumber(num); }

  return num.toLocaleString(
    undefined,
    { minimumFractionDigits: 2,
      maximumFractionDigits: 2 }
  );
}

export const formatInt = (num) => {
  if (BigNumber.isBigNumber(num)) { num = convertBigNumber(num); }

  return num.toLocaleString(
    undefined,
    { minimumFractionDigits: 0,
      maximumFractionDigits: 0 }
  );
}

export const formatDollars = (num) => {
  if (BigNumber.isBigNumber(num)) { num = convertBigNumber(num); }

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(num);
}

export const convertBigNumber = (bigNum) => {
  return Number(ethers.utils.formatEther(bigNum));
}

export const convertToBigNumber = (num) => {
  return BigNumber.from(num);
}

export const getPricePerJewel = (amount, lockedJewelAmount) => {
  return formatDollars(Number(amount.mul(100).div(lockedJewelAmount)) / 100);
}

export const getTotalPPJ = (pricePerJewel, lockedJewelAmount, raw = false) => {
  const total = convertToBigNumber(((pricePerJewel || 0) * 100).toFixed(0)).mul(lockedJewelAmount).div(100);
  return raw ? total : formatDollars(total);
}

export const getTotalReceived = (amount) => {
  let feeAmount = convertToBigNumber(0);

  if (contracts.data.totalVolumeTraded.gte(contracts.data.feeGracePeriodAmount)) {
    feeAmount = amount.mul(contracts.data.fee).div(1000);
  } else if ((contracts.data.totalVolumeTraded.add(amount)).gte(contracts.data.feeGracePeriodAmount)) {
    feeAmount = (amount.sub(contracts.data.feeGracePeriodAmount.sub(contracts.data.totalVolumeTraded))).mul(contracts.data.fee).div(1000);
  }

  return formatDollars(amount.sub(feeAmount));
}

export const getTotalReceivedPPJ = (pricePerJewel, lockedJewelAmount) => {
  return getTotalReceived(convertToBigNumber(((pricePerJewel || 0) * 100).toFixed(0)).mul(lockedJewelAmount).div(100));
}

export const amountMultiplier = (currency) => {
  return convertToBigNumber(10).pow(18 - contracts.currencyDecimals[currency]);
}

export const getCurrencyName = (address) => {
  return Object.keys(CURRENCIES).find(name => CURRENCIES[name] == address);
}

export const openAddress = (address) => {
  window.open(`${EXPLORER_URL}${address}`, '_blank').focus();
}

export const parseError = (err) => {
  if (err.custom) {
    return err.msg;
  } else {
    return null;
  }
}

export const timeBetween = (timestamp) => {
  // Get seconds between now and timestamp
  let secondsBetween = Math.abs(timestamp - Math.floor(Date.now() / 1000));

  let interval = secondsBetween / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years";
  }

  interval = secondsBetween / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }

  interval = secondsBetween / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }

  interval = secondsBetween / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }

  interval = secondsBetween / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }

  return Math.floor(secondsBetween) + " seconds";
}