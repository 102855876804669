<template>
  <div class="flex flex-col flex-grow">
    <p class="mb-4 font-semibold text-center">bid</p>

    <Row type='info' :data="{ text: 'buyer', value: swaps.bids.current.buyer, type: 'address' }" :top="true" />
    <Row type='info' :data="{ text: 'currency', value: swaps.bids.current.currency.name }" />
    <Row type='info' :data="{ text: 'price per jewel', value: getPricePerJewel(swaps.bids.current.amount, swaps.bids.current.swap.lockedJewelBalance) }" />
    <Row type='info' :data="{ text: 'locked jewel amount', value: formatNumber(swaps.bids.current.swap.lockedJewelBalance) }" />
    <Row type='info' :data="{ text: 'you receive', value: getTotalReceived(swaps.bids.current.amount) }" :bottom="true" />

    <button
      v-if="swaps.tx.state == TX_STATE.NONE"
      class="btn btn-purple h-16 mt-6"
      @click="acceptBid">accept bid</button>
    <div
      v-if="swaps.tx.state != TX_STATE.NONE"
      class="h-16 mt-6 border-2 text-purple flex font-semibold justify-center items-center">
      <div class="spinner mr-5"></div>
      <p v-if="swaps.tx.state == TX_STATE.WAITING">waiting for confirmation...</p>
      <p v-if="swaps.tx.state == TX_STATE.PENDING">tx pending...</p>
    </div>

    <div v-if="errorMsg != null" class="mt-4 -mb-2 flex justify-center">
      <p class="text-red text-center font-semibold">{{ errorMsg }}</p>
    </div>
    
    <div class="mt-6 flex justify-center" :class="{ 'invisible': swaps.tx.state != TX_STATE.NONE }" @click="swaps.clearCurrentBid">
      <p class="cursor-pointer select-none border-b border-transparent hover:border-current">back</p>
    </div>
  </div>
</template>

<script>
import swaps from '@/state/swaps';

import Row from '@/components/Row.vue';
import { TX_STATE } from '../constants';
import { formatNumber, getPricePerJewel, getTotalReceived, parseError } from '@/utils';

export default {
  name: 'SwapBidAccept',
  components: {
    Row,
  },
  data() { return { swaps, TX_STATE, formatNumber, getPricePerJewel, getTotalReceived, parseError, errorMsg: null, } },
  methods: {
    async acceptBid() {
      try {
        this.errorMsg = null;
        await swaps.acceptBid();
      } catch (err) {
        this.errorMsg = parseError(err) || `failed to accept bid`;
      }
    },
  }
}
</script>