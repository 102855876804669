<template>
  <div class="flex flex-col">
    <p class="mb-4 font-semibold text-center">step 2 of 2</p>

    <Row v-if="swaps.current.buyer != ZERO_ADDRESS" type='info' :data="{ text: 'buyer', value: swaps.current.buyer, type: 'address' }" :top="true" />
    <Row type='info' :data="{ text: 'swap contract', value: swaps.current.walletAddress, type: 'address' }" :top="swaps.current.buyer == ZERO_ADDRESS" />
    <Row type='info' :data="{ text: 'currency', value: swaps.current.ask.currency.name }" />
    <Row type='info' :data="{ text: 'price per jewel', value: getPricePerJewel(swaps.current.ask.amount, swaps.current.lockedJewelBalance) }" />
    <Row type='info' :data="{ text: 'locked jewel amount', value: formatNumber(swaps.current.lockedJewelBalance) }" />
    <Row type='info' :data="{ text: 'you receive', value: getTotalReceived(swaps.current.ask.amount) }" :bottom="true" />

    <button v-if="swaps.tx.state == TX_STATE.NONE" class="btn btn-pink h-16 mt-6" @click="fundSwap">fund swap</button>
    <div v-if="swaps.tx.state != TX_STATE.NONE" class="h-16 mt-6 border-2 flex font-semibold justify-center items-center">
      <div class="spinner mr-5"></div>
      <p v-if="swaps.tx.state == TX_STATE.WAITING">waiting for confirmation...</p>
      <p v-if="swaps.tx.state == TX_STATE.PENDING">tx pending...</p>
    </div>

    <div v-if="errorMsg != null" class="mt-4 -mb-2 flex justify-center">
      <p class="text-red text-center font-semibold">{{ errorMsg }}</p>
    </div>

    <div class="mt-6 flex justify-center" :class="{ 'invisible': swaps.tx.state != TX_STATE.NONE }">
      <p class="cursor-pointer select-none border-b border-transparent hover:border-current" @click="swaps.clearCurrentSwap">back</p>
    </div>
  </div>
</template>

<script>
import swaps from '@/state/swaps';

import Row from '@/components/Row.vue';
import { ZERO_ADDRESS, TX_STATE } from '../constants';
import { formatNumber, getPricePerJewel, getTotalReceived, parseError } from '@/utils';

export default {
  name: 'SwapFund',
  components: {
    Row,
  },
  data() { return { swaps, ZERO_ADDRESS, TX_STATE, formatNumber, getPricePerJewel, getTotalReceived, parseError, errorMsg: null, } },
  methods: {
    async fundSwap() {
      try {
        this.errorMsg = null;
        await swaps.fundSwap();
      } catch (err) {
        this.errorMsg = parseError(err) || `failed to fund swap`;
      }
    },
  }
}
</script>