<template>
  <div
    v-if="type == 'info'"
    class="px-6 py-4 border border-l-2 border-r-2 border-b-0 flex justify-between"
    :class="{ 'border-t-2': top,
              'border-b-2': bottom }">
    <p>{{ data.text }}</p>
    <p v-if="data.type == null" class="ml-2 font-semibold">{{ data.value }}</p>
    <p
      v-if="data.type == 'address'"
      class="ml-2 font-semibold cursor-pointer select-none border-b border-transparent hover:border-current"
      @click="openAddress(data.value)">{{ formatAddress(data.value) }}</p>
  </div>
  <div v-if="type == 'column' && (seller == null || !seller)">
    <div
      class="border border-l-2 border-r-2 border-b-0 flex"
      :class="{ 'text-pink border-pink': color == 'pink',
                'hover:bg-pink': color == 'pink' && !top,
                'text-green border-green': color == 'green',
                'hover:bg-green': color == 'green' && !top,
                'text-blue border-blue': color == 'blue',
                'hover:bg-blue': color == 'blue' && !top,
                'text-purple border-purple': color == 'purple',
                'hover:bg-purple': color == 'purple' && !top,
                'cursor-pointer select-none hover:text-white group': !top,
                'border-t-2 border-b-[1px]': top,
                'border-b-2': bottom }"
      @click="$emit('click')">
      <div
        v-for="(column, i) in data"
        :key="i"
        class="py-4 flex justify-center relative flex-1"
        :style="{ 'flex-grow': column.size || 1 }">
        <p class="text-center font-semibold">{{ column.value }}</p>
        <div
          v-if="i != (data.length - 1)"
          class="top-0 bottom-0 right-0 w-[1px] bg-current group-hover:invisible absolute" />
      </div>
    </div>
  </div>
  <div v-if="type == 'column' && seller">
    <div
      class="border border-l-2 border-r-2 border-b-0 flex text-pink border-green hover:bg-pink cursor-pointer select-none hover:text-white group"
      :class="{ 'border-b-2': bottom }"
      @click="$emit('click')">
      <div
        v-for="(column, i) in data"
        :key="i"
        class="py-4 flex justify-center relative flex-1"
        :style="{ 'flex-grow': column.size || 1 }">
        <p class="text-center font-semibold">{{ column.value }}</p>
        <div
          v-if="i != (data.length - 1)"
          class="top-0 bottom-0 right-0 w-[1px] bg-green group-hover:invisible absolute" />
      </div>
    </div>
  </div>
</template>

<script>
import { formatAddress, openAddress } from '@/utils';

export default {
  props: [ 'type', 'data', 'color', 'top', 'bottom', 'seller' ],
  emits: [ 'click' ],
  data() { return { formatAddress, openAddress } },
}
</script>