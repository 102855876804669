<template>
  <div class="flex flex-col">
    <p class="mb-4 font-semibold text-center">edit swap</p>

    <Row type='info' :data="{ text: 'current ask', value: getPricePerJewel(swaps.current.ask.amount, swaps.current.lockedJewelBalance) }" :top="true" />
    <div class="px-6 py-4 border-2 border-b-0 flex flex-col">
      <p class="mb-2 font-semibold">price per jewel</p>
      <Input class="mb-4" v-model="pricePerJewel" type="number" placeholder="$0" />

      <p class="mb-2 font-semibold">currency</p>
      <Select class="mb-4" v-model="currency" :options="Object.keys(CURRENCIES)" @update="(selectedCurrency) => currency = selectedCurrency" />
    </div>
    <Row type='info' :data="{ text: 'locked jewel amount', value: formatNumber(swaps.current.lockedJewelBalance) }" />
    <Row type='info' :data="{ text: 'you receive', value: getTotalReceivedPPJ(pricePerJewel, swaps.current.lockedJewelBalance) }" :bottom="true" />

    <button v-if="swaps.tx.state == TX_STATE.NONE" class="btn btn-pink h-16 mt-6" @click="updateSwap">update swap</button>
    <div v-if="swaps.tx.state != TX_STATE.NONE" class="h-16 mt-6 border-2 flex font-semibold justify-center items-center">
      <div class="spinner mr-5"></div>
      <p v-if="swaps.tx.state == TX_STATE.WAITING">waiting for confirmation...</p>
      <p v-if="swaps.tx.state == TX_STATE.PENDING">tx pending...</p>
    </div>

    <div v-if="errorMsg != null" class="mt-4 -mb-2 flex justify-center">
      <p class="text-red text-center font-semibold">{{ errorMsg }}</p>
    </div>

    <div class="mt-6 flex justify-center" :class="{ 'invisible': swaps.tx.state != TX_STATE.NONE }">
      <p class="cursor-pointer select-none border-b border-transparent hover:border-current" @click="swaps.cancelSwapEdit">back</p>
    </div>
  </div>
</template>

<script>
import swaps from '@/state/swaps'
import contracts from '@/state/contracts';

import Row from '@/components/Row.vue';
import Input from '@/components/Input.vue';
import Select from '@/components/Select.vue';

import { CURRENCIES, TX_STATE } from '@/constants';
import { formatNumber, getPricePerJewel, getTotalReceivedPPJ, parseError } from '@/utils';

export default {
  name: 'SwapEdit',
  components: {
    Row,
    Input,
    Select
  },
  data() {
    return {
      // Imports
      swaps,
      contracts,
      CURRENCIES,
      TX_STATE,
      formatNumber,
      getPricePerJewel,
      getTotalReceivedPPJ,

      // Input
      pricePerJewel: null,
      currency: swaps.current.ask.currency.name,

      // Error handling
      parseError,
      errorMsg: null,
    }
  },
  methods: {
    async updateSwap() {
      try {
        this.errorMsg = null;
        await swaps.updateSwap(this.pricePerJewel, this.currency);
      } catch (err) {
        this.errorMsg = parseError(err) || `failed to update swap`;
      }
    }
  },
  watch: {
    // Sanitization
    pricePerJewel: {
      handler() {
        this.$nextTick(() => {
          // Ensure no negative values
          if (this.pricePerJewel < 0) {
            this.pricePerJewel = '0';
          }

          // Limit to two digits after decimal
          if (this.pricePerJewel != null) {
            const price = this.pricePerJewel;
            this.pricePerJewel = (price.indexOf(".") >= 0) ? (price.substr(0, price.indexOf(".")) + price.substr(price.indexOf("."), 3)) : price;
          }
        });
      },
      immediate: true
    },
  },
}
</script>