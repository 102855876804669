<template>
  <div class="flex-grow pb-10 text-pink flex flex-col justify-center items-center">
    <div v-if="!ready" class="pb-6">
      <p v-if="web3.user.connection == WEB3_STATE.CONNECTED" class="font-semibold">LOADING SWAPS...</p>
      <p v-if="web3.user.connection == WEB3_STATE.INSTALL" class="font-semibold">INSTALL WALLET</p>
      <button class="btn btn-pink w-56 h-16" @click="web3.correctNetwork(null)" v-if="web3.user.connection == WEB3_STATE.WRONG_NETWORK">switch network</button>

      <div v-if="web3.user.connection == WEB3_STATE.CONNECT">
        <div class="w-[480px] px-6 py-4 border-2 font-semibold whitespace-pre-wrap">
          <p class="mb-2 border-b inline-block">how to sell your locked jewel</p>
          <p class="leading-8">{{ HOW_TO_SELL_LOCKED_JEWEL }}</p>
        </div>

        <button class="btn btn-pink w-full h-16 mt-6" @click="web3.connectWallet">connect wallet</button>
      </div>
    </div>

    <div v-if="ready && swaps.current.state == null" class="pb-6">
      <div v-if="swaps.sellerSwaps.length > 0" class="w-[512px]">
        <p class="mb-4 font-semibold text-center">your swaps</p>
        <Swaps type="sell" :swapArray="swaps.sellerSwaps" />
      </div>

      <div v-if="swaps.sellerSwaps.length == 0" class="w-[480px] px-6 py-4 border-2 font-semibold whitespace-pre-wrap">
        <p class="mb-2 border-b inline-block">how to sell your locked jewel</p>
        <p class="leading-8">{{ HOW_TO_SELL_LOCKED_JEWEL }}</p>
      </div>

      <button class="btn btn-pink w-full h-16 mt-6" @click="swaps.initSwap()">create swap</button>
    </div>

    <div v-if="ready && swaps.current.state != null && !swaps.bids.show" class="w-[360px] pt-6">
      <SwapCreate v-if="swaps.current.state == SWAP_STATE.EMPTY" />
      <SwapFund v-if="swaps.current.state == SWAP_STATE.CREATED" />
      <SwapFunded v-if="swaps.current.state == SWAP_STATE.FUNDED" />
      <SwapEdit v-if="swaps.current.state == SWAP_STATE.EDIT" />
    </div>

    <div v-if="ready && swaps.bids.current == null && swaps.current.state != null && swaps.bids.show" class="w-[360px] pt-6 text-purple">
      <p class="mb-4 font-semibold text-center">bids</p>
      <Bids class="mb-6" :bids="swaps.current.bids" type="sell" />

      <Row type='info' :data="{ text: 'current ask', value: getPricePerJewel(swaps.current.ask.amount, swaps.current.lockedJewelBalance) }" :top="true" />
      <Row type='info' :data="{ text: 'locked jewel amount', value: formatNumber(swaps.current.lockedJewelBalance) }" :bottom="true" />

      <div class="mt-6 flex justify-center">
        <p class="cursor-pointer select-none border-b border-transparent hover:border-current" @click="swaps.hideBids">back</p>
      </div>
    </div>

    <div v-if="ready && swaps.bids.current != null && swaps.current.state != null && swaps.bids.show" class="pt-6 text-purple">
      <SwapBidAccept v-if="swaps.current.state == SWAP_STATE.FUNDED" class="w-[360px]" />
      <SwapBidAccepted v-if="swaps.current.state == SWAP_STATE.SOLD" class="w-[320px]" />
    </div>
  </div>
</template>

<script>
import web3 from '@/state/web3'
import swaps from '@/state/swaps'

import Row from '@/components/Row.vue';

import { Swaps, SwapCreate, SwapFund, SwapFunded, SwapEdit, Bids, SwapBidAccept, SwapBidAccepted } from '@/components'
import { SWAP_STATE, HOW_TO_SELL_LOCKED_JEWEL, WEB3_STATE } from '@/constants'
import { getPricePerJewel, formatNumber } from '@/utils';

export default {
  name: 'Sell',
  components: {
    Row,

    Swaps,
    SwapCreate,
    SwapFund,
    SwapFunded,
    SwapEdit,

    Bids,
    SwapBidAccept,
    SwapBidAccepted,
  },
  beforeCreate() { swaps.clearCurrentSwap(); },
  data() { return { web3, swaps, getPricePerJewel, formatNumber, SWAP_STATE, HOW_TO_SELL_LOCKED_JEWEL, WEB3_STATE } },
  computed: {
    ready() {
      return swaps.sellerSwaps.value != null || swaps.current.state != null;
    },
  },
}
</script>