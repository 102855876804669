<template>
  <div class="flex-grow pb-10 text-blue flex flex-col justify-center items-center">
    <div v-if="!ready" class="pb-6">
      <p v-if="web3.user.connection == WEB3_STATE.CONNECTED" class="font-semibold">LOADING SWAPS...</p>
      <p v-if="web3.user.connection == WEB3_STATE.INSTALL" class="font-semibold">INSTALL WALLET</p>

      <button class="btn btn-pink w-56 h-16" @click="web3.connectWallet" v-if="web3.user.connection == WEB3_STATE.CONNECT">connect wallet</button>
      <button class="btn btn-pink w-56 h-16" @click="web3.correctNetwork(null)" v-if="web3.user.connection == WEB3_STATE.WRONG_NETWORK">switch network</button>
    </div>

    <div v-if="ready && swaps.current.state == null" class="w-[512px] pb-6">
      <div
        v-if="swaps.completedSwaps.length == 0"
        class="flex-grow flex flex-col justify-center items-center">
        <p class="font-semibold">NO SWAPS YET</p>
      </div>

      <div v-if="swaps.completedSwaps.length > 0" class="relative">
        <p class="top-6 w-full text-center font-semibold absolute">recent swaps</p>
        <div class="flex justify-end">
          <Select
            class="mb-4 pr-12 pl-4"
            v-model="swaps.sorting.activity"
            :options="Object.keys(SWAP_SORTING).map(key => SWAP_SORTING[key])"
            @update="(order) => swaps.setSortingActivity(order)" />
        </div>
        <Swaps type="activity" :swapArray="swaps.completedSwaps" />
      </div>
    </div>

    <div v-if="ready && swaps.current.state != null" class="w-[360px] pt-6">
      <SwapActivity />
    </div>
  </div>
</template>

<script>
import web3 from '@/state/web3'
import swaps from '@/state/swaps'

import Select from '@/components/Select.vue';
import { Swaps, SwapActivity } from '@/components'
import { SWAP_SORTING, WEB3_STATE } from '@/constants'

export default {
  name: 'Activity',
  components: {
    Select,

    Swaps,
    SwapActivity,
  },
  beforeCreate() { swaps.clearCurrentSwap(); },
  data() { return { web3, swaps, SWAP_SORTING, WEB3_STATE, } },
  computed: {
    ready() {
      return swaps.completedSwaps.value != null;
    },
  },
}
</script>