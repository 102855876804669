<template>
  <div class="flex-grow pb-4 sm:pb-48 mt-6 flex flex-col justify-center">
    <div class="mb-11 text-pink flex flex-col justify-center items-center">
      <p>TOTAL VOLUME TRADED</p>
      <div
        v-if="web3.user.connection == WEB3_STATE.CONNECTED && contracts.data.totalVolumeTraded == null"
        class="spinner mt-3 mb-2"></div>
      <p
        v-if="web3.user.connection != WEB3_STATE.CONNECTED"
        class="font-semibold text-[16px]">-</p>
      <p
        v-if="contracts.data.totalVolumeTraded != null"
        class="font-semibold text-[27px]">${{ formatInt(contracts.data.totalVolumeTraded || 0) }}</p>
    </div>
    <div class="flex justify-between items-stretch flex-col sm:flex-row gap-4">
      <button class="btn btn-green w-auto sm:w-56 h-56 px-6" @click="navigate('buy')">buy<br> locked jewel</button>
      <button class="btn btn-pink w-auto sm:w-56 h-56 px-6" @click="navigate('sell')">sell<br> locked jewel</button>
      <button class="btn btn-blue w-auto sm:w-56 h-56 px-6" @click="navigate('activity')">recent swaps</button>
    </div>
  </div>
</template>

<script>
import web3 from '@/state/web3';
import contracts from '@/state/contracts';

import { WEB3_STATE } from '@/constants'
import { formatInt } from '@/utils';

export default {
  name: 'Home',
  data() { return { web3, contracts, WEB3_STATE, formatInt } },
  methods: {
    async navigate(route) {
      if (web3.user.connection == WEB3_STATE.WRONG_NETWORK) {
        web3.correctNetwork(route);
      } else {
        this.$router.push(route);
      }
    },
  }
}
</script>