import { BigNumber, ethers } from 'ethers';

// URLs
export const TWITTER_URL = "https://twitter.com/somamine"

export const INSTALL_METAMASK_URL = "https://metamask.io/download.html"

export const ETHERSCAN_BASE_URL = "https://etherscan.io"

export const EXPLORER_URL = "https://explorer.harmony.one/address/"
// export const EXPLORER_URL = "https://explorer.pops.one/address/"

export const GITHUB_URL = "https://github.com/somamine/jewelswap"

// Web3
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const CORRECT_NETWORK = 1666600000;
// export const CORRECT_NETWORK = 1666700000; // testnet

export const GAS_PRICE = ethers.utils.parseUnits('50', 'gwei');

export const WEB3_STATE = {
    INSTALL: 0,
    CONNECT: 1,
    CONNECTED: 2,
    WRONG_NETWORK: 3
};

export const TX_STATE = {
    NONE: 0,
    WAITING: 1,
    PENDING: 2
}

export const PAGE_SIZE = 20;

// Swaps
export const SWAP_STATE = {
    EMPTY: 0,
    CREATED: 1,
    FUNDED: 2,
    SOLD: 3,
    CANCELED: 4,
    EDIT: 5,
};

export const SWAP_SORTING = {
    LOWEST_PRICE: 'lowest price',
    HIGHEST_PRICE: 'highest price',
    LOWEST_TOTAL: 'lowest total',
    HIGHEST_TOTAL: 'highest total',
    MOST_RECENT: 'most recent'
}

export const EXPIRATION_INTERVALS = {
    '3 days': BigNumber.from('259200'),
    '7 days': BigNumber.from('604800'),
    '30 days': BigNumber.from('2592000'),
}


// Currencies

export const CURRENCIES = {
    '$USDC': '0x985458E523dB3d53125813eD68c274899e9DfAb4',
    '$USDT': '0x3C2B8Be99c50593081EAA2A724F0B8285F5aba8f',
    '$DAI': '0xEf977d2f931C1978Db5F6747666fa1eACB0d0339',
    '$UST': '0x224e64ec1BDce3870a6a6c777eDd450454068FEC',
    '$BUSD': '0xE176EBE47d621b984a73036B9DA5d834411ef734'
};

// export const CURRENCIES = {
//     '$USDC': '0x33B465B61EBb322E6336437b2624F705a34a0Df0',
//     '$USDT': '0x12f839b098d1446ba9b25c4F6f7Ef49cc1846dEd',
//     '$DAI': '0xC27255D7805Fc79e4616d5CD50D6f4464AEa75A3',
//     '$UST': '0x12b7146CC70F2F1422B3b11B116f10F1925d256A',
//     '$BUSD': '0x7AB114Dd09411A940C0cCd89Dc8bE6325BE8C52c' // custom BUSD contract
//     // '$BUSD': '0x0E80905676226159cC3FF62B1876C907C91F7395' // actual testnet BUSD contract
// };

// Text
export const HOW_TO_SELL_LOCKED_JEWEL = `1. create a swap\n2. fund it with your locked jewel\n3. wait for a buyer at your asking price, accept a bid, or cancel the swap to get your locked jewel back`

export const INFO = [
    {
        title: `overview`,
        text: `jewelswap.xyz is a decentralized marketplace for trading locked $JEWEL.\nno centralized escrow service, just smart contracts.`
    },
    {
      title: `how to swap`,
        text: `1. seller initiates a swap\n2. seller transfers locked jewel to an escrow contract\n3. buyer accepts the swap or places a bid\n4. payment transferred to seller and locked jewel transferred to buyer`
    },
    {
        title: `payment`,
        text: `buyers can pay for locked $JEWEL using $USDC, $USDT, $DAI, $UST, or $BUSD.`
    },
    {
        title: `fees`,
        text: `there is no fee on the first $1 million in volume.\nafter that, there is a 2% fee on each swap.\nthe fee is automatically deducted from seller's final amount.`
    },
    {
        title: `contracts`,
        text: ``
    },
  ];