import { reactive, readonly, markRaw } from 'vue'

import { ethers } from 'ethers';
import { CORRECT_NETWORK, WEB3_STATE } from '@/constants';

/*
///////////////////////////////////////////////////////////////
												STATE
//////////////////////////////////////////////////////////////
*/

const user = reactive({	
	address: null,
	chainId: null,
	provider: null,
	signer: null,
	connection: WEB3_STATE.CONNECT,
});

let reloadRoute = null;

let initialized = false;

/*
///////////////////////////////////////////////////////////////
												PUBLIC
//////////////////////////////////////////////////////////////
*/

const init = () => {
	if (initialized) { return; }
	else { initialized = true; }

	// Get provider
	const ethereum = window.ethereum;
	if (ethereum == null) {
		user.connection = WEB3_STATE.INSTALL;
		return;
	} else {
		user.provider = markRaw(new ethers.providers.Web3Provider(ethereum));
	}

	if (user.provider) {
		// Get MetaMask account if connected
		ethereum.request({ method: 'eth_accounts' }).then(accountsChanged);

		// Get signer
		user.signer = user.provider.getSigner();

		// Detect account changes
		ethereum.on('accountsChanged', accountsChanged);

		// Detect chain changes
		ethereum.on('chainChanged', chainChanged);
	} else {
		user.connection = WEB3_STATE.INSTALL;
	}
}

const connectWallet = () => {
	window.ethereum.request({ method: 'eth_requestAccounts' });
}

const correctNetwork = async (route) => {
	reloadRoute = route;
	window.ethereum.request({ method: 'wallet_switchEthereumChain', params:[{ chainId: `0x${CORRECT_NETWORK.toString(16)}` }]});
}

/*
///////////////////////////////////////////////////////////////
												INTERNAL
//////////////////////////////////////////////////////////////
*/

const accountsChanged = (accounts) => {
	let address = accounts[0];
	if (address != null) { address = ethers.utils.getAddress(address); }

	if (user.address == null) {
		user.address = address;
		setConnection();
	} else {
		window.location.reload();
	}
}

const chainChanged = () => {
	if (reloadRoute != null) {
		window.location.href= `/${reloadRoute}`;
	} else {
		window.location.reload();
	}
}

const setConnection = async () => {
	if (user.provider != null && user.signer != null && user.address != null) {
		// Web3 connected, check chain id
		if (user.chainId == null) {
			user.chainId = window.ethereum.networkVersion;
		}

		if (user.chainId == CORRECT_NETWORK) {
			user.connection = WEB3_STATE.CONNECTED;
		} else {
			user.connection = WEB3_STATE.WRONG_NETWORK;
		}
	} else {
		user.connection = WEB3_STATE.CONNECT;
	}
}

/*
///////////////////////////////////////////////////////////////
												EXPORT
//////////////////////////////////////////////////////////////
*/

export default {
	user: readonly(user),

	init,
	connectWallet,
	correctNetwork,
};