<template>
  <div class="flex flex-col flex-grow">
    <Row
      type='column'
      :data="[
        { value: 'LOCKED JEWEL', size: 1.4 },
        { value: 'PRICE', size: 1 },
        { value: type == 'sell' ? 'YOU RECEIVE' : 'TOTAL', size: 1.4 },
      ]"
      :color="type == 'sell' ? 'pink' : type == 'buy' ? 'green' : 'blue'"
      :top="true" />
    <Row
      type='column'
      v-for="(swap, i) in swapArray"
      :key="i"
      :data="rowData(type, swap)"
      :color="type == 'sell' ? 'pink' : type == 'buy' ? 'green' : 'blue'"
      :bottom="i == (swapArray.length - 1)"
      :seller="type == 'buy' && swap.seller == web3.user.address"
      @click="onClick(type, swap)" />
  </div>
</template>

<script>
import web3 from '@/state/web3';
import swaps from '@/state/swaps'
import Row from '@/components/Row.vue';

import { formatNumber, formatDollars, getPricePerJewel, getTotalReceived } from '@/utils';

export default {
  name: 'Swaps',
  components: {
    Row,
  },
  props: [ 'swapArray', 'type' ],
  data() { return { web3, swaps } },
  methods: {
    onClick(type, swap) {
      if (type == 'buy' && swap.seller == web3.user.address) {
        this.$router.push('sell');
      } else {
        swaps.setCurrentSwap(swap);
      }
    },
    rowData(type, swap) {
      if (type == 'buy') {
        return [
          { value: formatNumber(swap.lockedJewelBalance), size: 1.4 },
          { value: getPricePerJewel(swap.ask.amount, swap.lockedJewelBalance), size: 1 },
          { value: formatDollars(swap.ask.amount), size: 1.4 },
        ];
      } else if (type == 'sell') {
        return [
          { value: formatNumber(swap.lockedJewelBalance), size: 1.4 },
          { value: getPricePerJewel(swap.ask.amount, swap.lockedJewelBalance), size: 1 },
          { value: getTotalReceived(swap.ask.amount), size: 1.4 },
        ];
      } else {
        return [
          { value: formatNumber(swap.sale.lockedJewelAmount), size: 1.4 },
          { value: getPricePerJewel(swap.sale.amount, swap.sale.lockedJewelAmount), size: 1 },
          { value: formatDollars(swap.sale.amount), size: 1.4 },
        ];
      }
    }
  }
}
</script>