<template>
  <input
    class="p-3 font-semibold border-2 outline-none box-border bg-none indent-1 placeholder"
    spellcheck="false"
    @keydown="keydown"
    :type="type"
    :placeholder="placeholder"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)" />
</template>

<script>
  export default {
    props: [ 'modelValue', 'type', 'placeholder' ],
    methods: {
      keydown(event) {
        if (this.type == 'number' && (event.keyCode == 69 || event.keyCode == 189)) {
          event.preventDefault();
        }
      }
    }
  }
</script>

<style scoped>

.placeholder::placeholder {
  font-weight: 400;
  color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

</style>