<template>
  <div class="flex flex-col flex-grow">
    <Row
      type='column'
      :data="columnTitles"
      color="purple"
      :top="true" />
    <Row
      type='column'
      v-for="(bid, i) in bids"
      :key="i"
      :data="rowData(bid)"
      color="purple"
      :bottom="i == (bids.length - 1)"
      @click="swaps.setCurrentBid(bid)" />
  </div>
</template>

<script>
import swaps from '@/state/swaps';

import Row from '@/components/Row.vue';
import { formatNumber, formatDollars, getPricePerJewel, getTotalReceived } from '@/utils';

export default {
  name: 'Bids',
  props: [ 'bids', 'type' ],
  components: {
    Row,
  },
  data() { return { swaps } },
  computed: {
    columnTitles() {
      if (this.type == 'all') {
        return [ { value: 'LOCKED JEWEL', size: 1.4 }, { value: 'BID', size: 1 }, { value: 'TOTAL', size: 1.4 } ];
      } else if (this.type == 'buy') {
        return [ { value: 'BID', size: 1 }, { value: 'TOTAL', size: 1.5 } ];
      } else {
        return [ { value: 'BID', size: 1 }, { value: 'YOU RECEIVE', size: 1.5 } ];
      }
    },
  },
  methods: {
    rowData(bid) {
      if (this.type == 'all') {
        return [
          { value: formatNumber(bid.swap.lockedJewelBalance), size: 1.4 },
          { value: getPricePerJewel(bid.amount, bid.swap.lockedJewelBalance), size: 1 },
          { value: formatDollars(bid.amount), size: 1.4 }
        ];
      } else if (this.type == 'buy') {
        return [
          { value: getPricePerJewel(bid.amount, bid.swap.lockedJewelBalance), size: 1 },
          { value: formatDollars(bid.amount), size: 1.5 }
        ]
      } else {
        return [
          { value: getPricePerJewel(bid.amount, bid.swap.lockedJewelBalance), size: 1 },
          { value: getTotalReceived(bid.amount), size: 1.5 }
        ]
      }
    }
  }
}
</script>