<template>
  <div class="flex flex-col">
    <p class="mb-4 font-semibold text-center">swap success</p>
    <img class="border-2" :src="moneypepe" />
    <button class="btn btn-purple h-16 mt-6" @click="swaps.clearCurrentSwap">done</button>
  </div>
</template>

<script>
import swaps from '@/state/swaps';
import moneypepe from '@/assets/images/moneypepe.jpg'

export default {
  name: 'SwapBidAccepted',
  data() { return { swaps, moneypepe } },
}
</script>