<template>
  <div class="w-full py-6 top-0 bg-white flex flex-col justify-center items-center static sm:sticky z-20">
    <p class="text-center text-purple text-[28px] cursor-pointer z-10" @click="$router.push('/')">JEWELSWAP</p>
    <p class="mt-1 text-center text-pink text-[16px]" v-if="$route.name == 'home'">trustlessly trade locked jewel</p>

    <div class="top-4 left-0 right-0 w-full mt-6 md:mt-0 flex justify-between flex-col-reverse sm:flex-row gap-4 md:absolute">
      <div class="h-14 flex-grow md:flex-grow-0 flex">
        <button class="btn text-twitter px-6 hover:bg-twitter flex-grow" @click="openTwitter">twttr</button>
        <button class="btn btn-purple px-6 ml-4 flex-grow" :class="{ 'btn-purple-flipped': $route.name == 'info' }" @click="$router.push('info')">about</button>
      </div>

      <div class="h-14 flex gap-4">
        <div class="px-0 sm:px-8 flex-1 sm:flex-grow-0 border-2 text-green font-semibold flex justify-center items-center gap-2">
          <p>{{ formatNumber(contracts.data.lockedJewelBalance) }}</p>
          <img :src="jewelIcon" class="w-5 mb-[3px]" />
        </div>
        <button class="btn btn-pink sm:px-6 flex-1" @click="install" v-if="web3.user.connection == WEB3_STATE.INSTALL">install wallet</button>
        <button class="btn btn-pink sm:px-6 flex-1" @click="web3.connectWallet" v-if="web3.user.connection == WEB3_STATE.CONNECT">connect wallet</button>
        <button class="btn btn-pink sm:px-6 flex-1" v-if="web3.user.connection == WEB3_STATE.CONNECTED">{{formatAddress(web3.user.address)}}</button>
        <button class="btn btn-pink sm:px-6 flex-1" @click="changeNetwork" v-if="web3.user.connection == WEB3_STATE.WRONG_NETWORK">wrong network</button>
      </div>
    </div>
  </div>
</template>

<script>
import web3 from '@/state/web3'
import contracts from '@/state/contracts';

import jewelIcon from '@/assets/images/jewel-icon.png'
import { WEB3_STATE, INSTALL_METAMASK_URL, CORRECT_NETWORK } from '@/constants';
import { formatAddress, formatNumber } from '@/utils';
import { TWITTER_URL } from '../constants';

export default {
  name: 'Buy',
  data() { return { web3, contracts, jewelIcon, WEB3_STATE, formatAddress, formatNumber } },
  methods: {
    install() {
      window.open(INSTALL_METAMASK_URL, '_blank').focus();
    },
    changeNetwork() {
      window.ethereum.request({ method: 'wallet_switchEthereumChain', params:[{ chainId: `0x${CORRECT_NETWORK.toString(16)}` }]});
    },
    openTwitter() {
      window.open(TWITTER_URL, '_blank').focus();
    },
  },
}
</script>