<template>
  <div class="flex flex-col flex-grow">
    <p class="mb-4 font-semibold text-center">swap</p>
    <Row type='info' :data="{ text: 'seller', value: swaps.current.seller, type: 'address' }" :top="true" />
    <Row type='info' :data="{ text: 'buyer', value: swaps.current.sale.buyer, type: 'address' }" />
    <Row type='info' :data="{ text: 'date', value: `${timeBetween(swaps.current.sale.soldAt)} ago` }" />
    <Row type='info' :data="{ text: 'currency', value: swaps.current.sale.currency.name }" />
    <Row type='info' :data="{ text: 'price per jewel', value: getPricePerJewel(swaps.current.sale.amount, swaps.current.sale.lockedJewelAmount) }" />
    <Row type='info' :data="{ text: 'locked jewel amount', value: formatNumber(swaps.current.sale.lockedJewelAmount) }" />
    <Row type='info' :data="{ text: 'total', value: formatDollars(swaps.current.sale.amount) }" :bottom="true" />

    <div class="mt-6 flex justify-center">
      <p class="cursor-pointer select-none border-b border-transparent hover:border-current" @click="swaps.clearCurrentSwap">back</p>
    </div>
  </div>
</template>

<script>
import swaps from '@/state/swaps';

import Row from '@/components/Row.vue';
import { formatNumber, formatDollars, getPricePerJewel, timeBetween } from '@/utils';

export default {
  name: 'SwapActivity',
  components: {
    Row,
  },
  data() { return { swaps, formatNumber, formatDollars, getPricePerJewel, timeBetween, } },
}
</script>