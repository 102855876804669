<template>
  <div class="w-full mb-4 sm:mb-8 flex-shrink-0 flex justify-between flex-col sm:flex-row gap-4">
    <button
      class="btn btn-green h-16 w-auto sm:w-56"
      :class="{ 'btn-green-flipped': $route.name == 'buy' }"
      @click="navigate('buy')">
      <p>buy</p>
    </button>
    <button
      class="btn btn-pink h-16 w-auto sm:w-56"
      :class="{ 'btn-pink-flipped': $route.name == 'sell' }"
      @click="navigate('sell')">
      <p>sell</p>
    </button>
    <button
      class="btn btn-blue h-16 w-auto sm:w-56"
      :class="{ 'btn-blue-flipped': $route.name == 'activity' }"
      @click="navigate('activity')">
      <p>recent</p>
    </button>
  </div>
</template>

<script>
import swaps from '@/state/swaps'

export default {
  name: 'Buy',
  methods: {
    async navigate(route) {
      swaps.clearCurrentSwap();
      this.$router.push(route);
    }
  },
}
</script>