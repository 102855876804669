<template>
  <div class="flex flex-col">
    <p class="mb-4 font-semibold text-center">{{ swaps.bids.current.buyer == web3.user.address ? 'your bid' : 'bid' }}</p>

    <Row type='info' :data="{ text: 'seller', value: swaps.bids.current.swap.seller, type: 'address' }" :top="true" />
    <Row v-if="swaps.bids.current.buyer != web3.user.address" type='info' :data="{ text: 'bidder', value: swaps.bids.current.buyer, type: 'address' }" />
    <Row type='info' :data="{ text: 'swap contract', value: swaps.bids.current.swap.walletAddress, type: 'address' }" />
    <Row type='info' :data="{ text: 'expires in', value: timeBetween(swaps.bids.current.expiryTime) }" />
    <Row type='info' :data="{ text: 'price per jewel', value: getPricePerJewel(swaps.bids.current.amount, swaps.bids.current.swap.lockedJewelBalance) }" />
    <Row type='info' :data="{ text: 'locked jewel amount', value: formatNumber(swaps.bids.current.swap.lockedJewelBalance) }" />
    <Row type='info' :data="{ text: 'total', value: `${formatNumber(swaps.bids.current.amount)} ${swaps.bids.current.currency.name}` }" :bottom="true" />

    <div v-if="swaps.bids.current.buyer == web3.user.address" class="h-16 mt-6 flex">
      <button v-if="swaps.tx.state == TX_STATE.NONE" class="btn btn-purple flex-1" @click="cancelBid">cancel bid</button>
      <button v-if="swaps.tx.state == TX_STATE.NONE && swaps.current.state == null" class="btn btn-purple flex-1 ml-4" @click="viewSwap">view swap</button>
      <div v-if="swaps.tx.state != TX_STATE.NONE" class="flex-1 border-2 flex font-semibold justify-center items-center">
        <div class="spinner mr-5"></div>
        <p v-if="swaps.tx.state == TX_STATE.WAITING">waiting for confirmation...</p>
        <p v-if="swaps.tx.state == TX_STATE.PENDING">tx pending...</p>
      </div>
    </div>

    <div v-if="errorMsg != null" class="mt-4 -mb-2 flex justify-center">
      <p class="text-red text-center font-semibold">{{ errorMsg }}</p>
    </div>

    <div class="mt-6 flex justify-center" :class="{ 'invisible': swaps.tx.state != TX_STATE.NONE }">
      <p class="cursor-pointer select-none border-b border-transparent hover:border-current" @click="swaps.clearCurrentBid">back</p>
    </div>
  </div>
</template>

<script>
import web3 from '@/state/web3';
import swaps from '@/state/swaps';

import Row from '@/components/Row.vue';
import { TX_STATE } from '../constants';
import { formatNumber, getPricePerJewel, timeBetween, parseError } from '@/utils';

export default {
  name: 'SwapFunded',
  components: {
    Row,
  },
  data() { return { web3, swaps, TX_STATE, formatNumber, getPricePerJewel, timeBetween, parseError, errorMsg: null, } },
  methods: {
    async cancelBid() {
      try {
        this.errorMsg = null;
        await swaps.cancelBid(swaps.bids.current.swap.id);
      } catch (err) {
        this.errorMsg = parseError(err) || `failed to cancel bid`;
      }
    },
    viewSwap() {
      const swap = swaps.openSwaps.value.find(swap => swap.id.eq(swaps.bids.current.swap.id));
      swaps.setCurrentSwap(swap);
    },
  }
}
</script>