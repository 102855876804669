<template>
  <div class="p-3 font-semibold text-left border-2 select-none relative cursor-pointer after:arrow z-10" @click="showOptions = !showOptions">
    <p>{{ modelValue }}</p>
    <div class="top-full left-[-2px] right-[-2px] flex-col absolute" v-if="showOptions">
      <div
        class="bg-white p-3 font-normal text-left border-2 border-b-0 select-none relative cursor-pointer"
        v-for="(option, i) in options"
        :key="i"
        :class="{ 'border-b-2': i == (options.length - 1) }"
        @click="$emit('update', option)">
          <p :class="{ 'font-semibold': modelValue == option }">{{ option }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: [ 'modelValue', 'options' ],
    emits: [ 'update' ],
    data() {
      return {
        showOptions: false,
      }
    },
  }
</script>