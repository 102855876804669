import { createRouter, createWebHistory } from 'vue-router'
import { Home, Info, Sell, Buy, Activity } from './pages';

const routes = [
  // Home
  { path: '/', name: 'home', component: Home },

  // Pages
  { path: '/info', name: 'info', component: Info },
  { path: '/sell', name: 'sell', component: Sell },
  { path: '/buy', name: 'buy', component: Buy },
  { path: '/activity', name: 'activity', component: Activity },

  // Fallback
  { path: '/:catchAll(.*)', redirect: '/' }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router
